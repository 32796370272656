<template>
  <div class="main">
    <div class="base-info">
      <div class="name">{{info.display_name}}</div>
      <div class="name-en">{{info.inci}}</div>
      <div class="cas" v-if="info.cas !== ''">cas: {{info.cas}}</div>

      <div class="risk-score risk-1" v-if="info.risk>=0 && info.risk<=2">
        <div class="score">{{info.risk}}</div>
        <div class="intro">低风险</div>
      </div>
      <div class="risk-score risk-2" v-if="info.risk>=3 && info.risk<=5">
        <div class="score">{{info.risk}}</div>
        <div class="intro">中风险</div>
      </div>
      <div class="risk-score risk-3" v-if="info.risk>=6 && info.risk<=9">
        <div class="score">{{info.risk}}</div>
        <div class="intro">高风险</div>
      </div>
    </div>
    <div class="section use-num">有 <span>{{ info.use_num }}</span> 种产品使用了该成分</div>

    <div class="section" v-if="info.target_name_list && info.target_name_list.length">
      <div class="label">成分用途</div>
      <van-tag v-for="item in info.target_name_list" :key="item" plain type="warning" size="large">{{ item }}</van-tag>
    </div>

    <div class="section" v-if="info.risk_list && info.risk_list.length">
      <div class="label">风险</div>
      <van-tag v-for="item in info.risk_list" :key="item.id" plain type="danger" size="large">{{ item.name }}</van-tag>
    </div>

    <div class="section" v-if="info.about">
      <div class="label">成分说明</div>
      <div>{{info.about}}</div>
    </div>

  </div>
</template>

<script>

import {
  ref,
  onMounted
} from 'vue';
import { useRoute } from 'vue-router';
import {Icon, Tag, Popup, Col, Row} from 'vant';
import Product from "../../models/Product";

export default {
  setup() {
    const route = useRoute();
    let id = route.query.id;
    let info = ref({});

    onMounted(() => {
      let param = {
        'id': id
      }
      Product.makeupDetail(param).then(res => {
        console.log(res);
        info.value = res.data
      });
    });

    return {
      info,
    };
  },
  data() {
    return {
    }
  },
  methods: {

  },
  components: {
    [Icon.name]: Icon,
    [Tag.name]:Tag,
    [Popup.name]:Popup,
    [Col.name]:Col,
    [Row.name]:Row
  }
};
</script>
<style scoped>
.main {
  letter-spacing: 1px;
  text-align: left;
  background-color: #f6f6f6;
}
.base-info {
  background-color: #fff;
  padding: 10px 15px;
  position: relative;
}
.base-info .name {
  margin: 38px 60px 10px 0;
  font-size: 20px;
  font-weight: 700;
}
.base-info .name-en, .base-info .cas {
  font-size: 14px;
  line-height: 22px;
  margin-right: 60px;
}

.use-num span {
  color: #fc5490;
}
.base-info .risk-score {
  position: absolute;
  right: 25px;
  top: 50px;
  padding: 0;
  text-align: center;
  border-radius: 6px;
  color: #fff;
  width: 50px;
}
.risk-score .score {
  font-size: 20px;
  padding-top: 5px;
  font-weight: 700;
}
.risk-score .intro {
  font-size: 12px;

  width: 100%;
  border-radius: 3px 3px 6px 6px;
}
.risk-score.risk-1 {
  background-color: #1cdd59;
}
.risk-score.risk-1 .intro {
  background-color: #07c160;
}
.risk-score.risk-2 {
  background-color: #fea138;
}
.risk-score.risk-2 .intro {
  background-color: #fd7728;
}
.risk-score.risk-3 {
  background-color: #fd2828;
}
.risk-score.risk-3 .intro {
  background-color: #ee0a28;
}

.section {
  background-color: #fff;
  margin-top: 8px;
  padding: 10px 15px;
}
.section .label {
  font-size: 18px;
  font-weight: 700;
  padding: 5px 0;
}
.section .van-tag {
  margin: 3px 15px 3px 0;
}

</style>
